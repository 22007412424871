define("discourse/plugins/DiscourseVirtmail/discourse/controllers/addresses-index", ["exports", "@ember/object", "@ember/controller", "I18n", "bootbox", "discourse/lib/ajax-error"], function (_exports, _object, _controller, _I18n, _bootbox, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    addresses: {},
    resetPassword(address) {
      address.resetPassword();
    },
    destroy(address) {
      return _bootbox.default.confirm(_I18n.default.t("discourse-virtmail.addresses.delete_confirm"), _I18n.default.t("no_value"), _I18n.default.t("yes_value"), result => {
        if (result) {
          address.destroyRecord().then(() => {
            this.model.removeObject(address);
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    }
  }, [["method", "resetPassword", [_object.action]], ["method", "destroy", [_object.action]]]));
});