define("discourse/plugins/DiscourseVirtmail/discourse/components/virtmail-list", ["exports", "@ember/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.collection}}
    <div class="values">
      {{#each this.collection as |value index|}}
        <div data-index={{index}} class="value">
          <DButton
            @action={{action "removeValue"}}
            @actionParam={{value}}
            @icon="times"
            @class="remove-value-btn btn-small"
          />
  
          <Input
            title={{value}}
            @value={{value}}
            class="value-input"
            {{on "focusout" (fn (action "changeValue") index)}}
          />
        </div>
      {{/each}}
    </div>
  {{/if}}
  
  <div class="simple-list-input">
    <Input
      @type="text"
      @value={{this.newValue}}
      placeholder={{i18n "admin.site_settings.simple_list.add_item"}}
      class="add-value-input"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
    />
  
    <DButton
      @action={{action "addValue"}}
      @actionParam={{this.newValue}}
      @disabled={{this.inputEmpty}}
      @icon="plus"
      @class="add-value-btn btn-small"
    />
  </div>
  
  */
  {
    "id": "4cWTzlkQ",
    "block": "[[[41,[30,0,[\"collection\"]],[[[1,\"  \"],[10,0],[14,0,\"values\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"collection\"]]],null]],null],null,[[[1,\"      \"],[10,0],[15,\"data-index\",[30,2]],[14,0,\"value\"],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@action\",\"@actionParam\",\"@icon\",\"@class\"],[[28,[37,4],[[30,0],\"removeValue\"],null],[30,1],\"times\",\"remove-value-btn btn-small\"]],null],[1,\"\\n\\n        \"],[8,[39,5],[[16,\"title\",[30,1]],[24,0,\"value-input\"],[4,[38,6],[\"focusout\",[28,[37,7],[[28,[37,4],[[30,0],\"changeValue\"],null],[30,2]],null]],null]],[[\"@value\"],[[30,1]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"simple-list-input\"],[12],[1,\"\\n  \"],[8,[39,5],[[16,\"placeholder\",[28,[37,8],[\"admin.site_settings.simple_list.add_item\"],null]],[24,0,\"add-value-input\"],[24,\"autocomplete\",\"off\"],[24,\"autocorrect\",\"off\"],[24,\"autocapitalize\",\"off\"]],[[\"@type\",\"@value\"],[\"text\",[30,0,[\"newValue\"]]]],null],[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@action\",\"@actionParam\",\"@disabled\",\"@icon\",\"@class\"],[[28,[37,4],[[30,0],\"addValue\"],null],[30,0,[\"newValue\"]],[30,0,[\"inputEmpty\"]],\"plus\",\"add-value-btn btn-small\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"value\",\"index\"],false,[\"if\",\"each\",\"-track-array\",\"d-button\",\"action\",\"input\",\"on\",\"fn\",\"i18n\"]]",
    "moduleName": "discourse/plugins/DiscourseVirtmail/discourse/components/virtmail-list.hbs",
    "isStrictMode": false
  });
  class VirtmailList extends _component.default {
    inputDelimiter = null;
    newValue = "";
    collection = null;
    values = null;
    didReceiveAttrs() {
      this._super(...arguments);
      this.set("collection", this.values || []);
    }
    keyDown(event) {
      if (event.which === 13) {
        this.addValue(this.newValue);
        return;
      }
    }
    changeValue(index, event) {
      this.collection.replace(index, 1, [event.target.value]);
      this.collection.arrayContentDidChange(index);
      this._saveValues();
    }
    static #_ = (() => dt7948.n(this.prototype, "changeValue", [_object.action]))();
    addValue(newValue) {
      if (this.inputEmpty) {
        return;
      }
      this.set("newValue", null);
      this.collection.addObject(newValue);
      this._saveValues();
    }
    static #_2 = (() => dt7948.n(this.prototype, "addValue", [_object.action]))();
    removeValue(value) {
      this.collection.removeObject(value);
      this._saveValues();
    }
    static #_3 = (() => dt7948.n(this.prototype, "removeValue", [_object.action]))();
    get inputEmpty() {
      return !this.newValue;
    }
    static #_4 = (() => dt7948.n(this.prototype, "inputEmpty", [(0, _object.computed)("newValue")]))();
    _saveValues() {
      this.set("values", this.collection);
    }
  }
  _exports.default = VirtmailList;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, VirtmailList);
});