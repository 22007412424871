define("discourse/plugins/DiscourseVirtmail/discourse/routes/discourse-virtmail-addresses", ["exports", "@ember/object", "discourse/routes/discourse"], function (_exports, _object, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend(dt7948.p({
    controllerName: "addresses",
    renderTemplate() {
      this.render("addresses");
    },
    show(address) {
      this.transitionTo("discourse-virtmail.addresses.show", address.id);
    },
    new() {
      this.transitionTo("discourse-virtmail.addresses.new");
    }
  }, [["method", "show", [_object.action]], ["method", "new", [_object.action]]]));
});